import React from 'react';

import CustomLink from 'components/utils/CustomLink';
import FormattedMessage from 'components/utils/FormattedMessage';

const PartnerMenu = ({ activePartner }) => (
    <ul className="tabs tabs--NoScrollDesk">
        <li>
            <CustomLink
                namedRoute="mainPartners"
                className={`${activePartner === 'mainPartners' ? 'active' : 'inactive'}`}
            >
                <FormattedMessage id="partners.mainPartners" />
            </CustomLink>
        </li>
        <li>
            <CustomLink
                namedRoute="officialPartners"
                className={`${activePartner === 'officialPartners' ? 'active' : 'inactive'}`}
            >
                <FormattedMessage id="partners.officialPartners" />
            </CustomLink>
        </li>
        <li>
            <CustomLink
                namedRoute="clubPartners"
                className={`${activePartner === 'clubPartners' ? 'active' : 'inactive'}`}
            >
                <FormattedMessage id="partners.clubPartners" />
            </CustomLink>
        </li>
        <li>
            <CustomLink
                namedRoute="heelkickPartners"
                className={`${activePartner === 'heelkickPartners' ? 'active' : 'inactive'}`}
            >
                <FormattedMessage id="partners.heelkickPartners" />
            </CustomLink>
        </li>
        <li>
            <CustomLink
                namedRoute="clubchairPartners"
                className={`${activePartner === 'clubchairPartners' ? 'active' : 'inactive'}`}
            >
                <FormattedMessage id="partners.clubchairPartners" />
            </CustomLink>
        </li>
        <li>
            <CustomLink
                namedRoute="supportPartners"
                className={`${activePartner === 'supportPartners' ? 'active' : 'inactive'}`}
            >
                Club 1889
            </CustomLink>
        </li>
        <li>
            <CustomLink
                namedRoute="csrPartners"
                className={`${activePartner === 'csrPartners' ? 'active' : 'inactive'}`}
            >
                <FormattedMessage id="partners.csrPartners" />
            </CustomLink>
        </li>
    </ul>
);

export default PartnerMenu;
