import { useStaticQuery, graphql } from 'gatsby';

const useClubPartnersPage = () => {
    const { sanityPageClubPartners: PageClubPartners } = useStaticQuery(graphql`
        query {
            sanityPageClubPartners {
                bannerImage {
                    image {
                        ...ImageWithPreview
                    }
                    alt
                }
                title {
                    en
                    se
                }
                description {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                clubPartnersMen {
                    title
                    url
                    logo {
                        ...ImageWithPreview
                    }
                }
                clubPartnersWomen {
                    title
                    url
                    logo {
                        ...ImageWithPreview
                    }
                }
            }
        }
    `);

    return PageClubPartners;
};

export default useClubPartnersPage;
